import { TicketCarrier } from '../../features/modules/state/interfaces';

export function getVisibleTicketCarriers(ticketCarriers: TicketCarrier[] = []): TicketCarrier[] {
    return ticketCarriers
        .filter(ticketCarrier => !isDefaultTicketCarrier(ticketCarrier))
}

export function getDefaultTicketCarrier(ticketCarriers: TicketCarrier[] = []): TicketCarrier | undefined {
    return ticketCarriers
        .find(isDefaultTicketCarrier);
}

export function isDefaultTicketCarrier({ cardNumber, cardOwner, cardName }: TicketCarrier = {} as TicketCarrier) {
    return cardNumber === '0' &&
        cardOwner === 'system' &&
        cardName === 'default';
}
