import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'button [btn-icon]',
    templateUrl: './icon-button.component.html',
    styleUrls: ['./icon-button.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class IconButtonComponent {

    @Input() icon?: string;

}
