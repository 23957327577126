import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ContentChild, ElementRef, Input, TemplateRef, ViewChild } from '@angular/core';

import * as AppStateActions from '../../../features/modules/state/app-state.actions';

@Component({
    selector: `app-navigation`,
    templateUrl: `navigation.component.html`,
    styleUrls: [`./navigation.component.scss`],
    animations: [
        trigger('fadeInOutAnimation', [
            transition(':enter', [
                style({ height: '0' }),
                animate('300ms ease-out'),
            ]),
            transition(':leave', [
                animate('300ms ease-out', style({ height: '0' }))
            ])
        ])
    ]
})
export class NavigationComponent {
    // Templates.
    @ContentChild(`mobileHeaderTemplate`) mobileHeaderTemplate!: TemplateRef<any>;

    @ContentChild(`desktopBeforeTemplate`) desktopBeforeTemplate!: TemplateRef<any>;
    @ContentChild(`desktopAfterTemplate`) desktopAfterTemplate!: TemplateRef<any>;

    @ViewChild(`mobileRoutes`) mobileRoutes!: ElementRef;

    @Input() desktopNavigation!: any[];
    @Input() mobileNavigation!: any[];

    isHamburgerVisible = false;

    constructor(
        private readonly router: Router,
        private readonly store: Store,
    ) {}

    toggle() {
        this.store.dispatch(new AppStateActions.HideCart(true));

        this.isHamburgerVisible = !this.isHamburgerVisible;
    }

    navigate(url: string) {
        this.isHamburgerVisible = false;

        void this.router.navigate([url]);
    }
}
