<div class="product-wrapper flex">
    <div
        *ngIf="product"
        class="product bg-cover"
        [class.clickable]="clickable"
        [routerLink]="clickable ? [product.id] : []"
        title="{{ product.name }}"
    >

        <div class="content relative">
            <div class="content-name">{{ product.name }}</div>
            <div *ngIf="detailsVisible">
                <div class="content-item">{{ product.description }}</div>
                <div class="content-item">
                    {{ 'shop.price' | translate }}: {{ product.priceWithTax / 100 | currency: 'PLN' }}
                </div>
            </div>

            <ng-content></ng-content>
        </div>

        <div class="ml-auto image-wrapper">
            <img
                class="product-image"
                [src]="product.file ? domSanitizer.bypassSecurityTrustResourceUrl(product.file) : '/assets/local-wkd/logo.png'"
                [alt]="product.name"
            />
        </div>
    </div>
</div>
