import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

import { ProductCardComponent } from './product-card.component';

const declarations = [
    ProductCardComponent,
];

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,
    ],
    declarations,
    exports: declarations,
})
export class ProductCardModule {
}
