<ng-container
    *ngIf="!(serviceMode$ | async); else serviceMode"
>
    <app-layout>
        <router-outlet></router-outlet>
    </app-layout>
    <p-toast position="bottom-center"></p-toast>

    <p-dialog
        class="grid"
        [style]="{ width: '800px' }"
        [header]="'main.popupTitle' | translate"
        [(visible)]="popupVisible"
        position="center"
        [closable]="false"
        [draggable]="false"
        [resizable]="false"
        [modal]="true"
    >
        <div>
            <div [innerHtml]="popupText"></div>
            <div class="flex justify-end gap-3 pt-5">
                <button pButton type="button" [label]="'main.accept' | translate" icon="pi pi-check"
                        (click)="acceptPopup()"></button>
                <button
                    pButton
                    type="button"
                    [label]="'main.reject' | translate"
                    icon="pi pi-times"
                    (click)="denyPopup()"
                    [disabled]="denyButtonDisabled"
                ></button>
            </div>
        </div>
    </p-dialog>
</ng-container>
<ng-template #serviceMode>
    <div>
        <div class="maintenance"></div>

        <h2 class="text-3xl text-center">Prace serwisowe w trakcie</h2>
    </div>

</ng-template>
