// eslint-disable-next-line import/no-unassigned-import
// import '@angular/common/locales/global/pl';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { CommonModule } from '@angular/common';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FooterComponent } from '../footer/footer.component';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { LayoutComponent } from './layout.component';
import { NavigationComponent } from '../navigation/navigation.component';
import { NgModule } from '@angular/core';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TranslateModule } from '@ngx-translate/core';
import { RadioButtonModule } from 'primeng/radiobutton';
import { IconButtonComponent } from '../icon-button/icon-button.component';
import { ProductCardModule } from '../product-card/product-card.module';

const primeng = [
    DropdownModule,
    DialogModule,
    CheckboxModule,
    OverlayPanelModule,
    InputNumberModule,
    InputTextModule,
    InputMaskModule,
    ButtonModule,
    ConfirmDialogModule,
    RadioButtonModule,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ...primeng,
        TranslateModule,
        ProductCardModule,
    ],
    declarations: [
        FooterComponent,
        NavigationComponent,
        LayoutComponent,
        IconButtonComponent,
    ],
    exports: [LayoutComponent],
    providers: [ConfirmationService],
})
export class LayoutModule {}
