import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { Product } from '../../../features/modules/state/interfaces';

@Component({
    selector: 'app-product-card [product]',
    templateUrl: './product-card.component.html',
    styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent {

    @Input() product?: Product;
    @Input() clickable = false;
    @Input() detailsVisible = true;

    constructor(public readonly domSanitizer: DomSanitizer) {
    }

}
