<!-- Mobile -->
<nav class="mobile">
    <div class="grid" style="grid-template-columns: 1fr auto 1fr">
        <!-- Toggle -->
        <div>
            <span class="toggle material-icons" (click)="toggle()">menu</span>
        </div>

        <!-- Header -->
        <ng-container *ngTemplateOutlet="mobileHeaderTemplate || defaultMobileHeaderTemplate"></ng-container>
        <ng-template #defaultMobileHeaderTemplate></ng-template>
    </div>

    <!-- Routes -->
    <ul
        *ngIf="isHamburgerVisible"
        #mobileRoutes
        class="mobile--router"
        @fadeInOutAnimation
    >
        <li class="mobile--item" *ngFor="let item of mobileNavigation">
            <a class="mobile--item--link" (click)="navigate(item.link)">
                <span class="mobile--item--link--text">{{ item.name | translate }}</span>
            </a>
        </li>
    </ul>
</nav>

<div class="mobile--pusher">
    <span class="toggle material-icons">menu</span>
</div>

<!-- Desktop -->
<nav class="desktop">
    <!-- Before routes -->
    <ng-container *ngTemplateOutlet="desktopBeforeTemplate || defaultDesktopBeforeTemplate"></ng-container>
    <ng-template #defaultDesktopBeforeTemplate></ng-template>

    <!-- Routes -->
    <ul class="desktop--router">
        <li class="desktop--item" *ngFor="let item of desktopNavigation">
            <a class="desktop--item--link" (click)="navigate(item.link)">
                <span class="desktop--item--link--text">{{ item.name | translate }}</span>
            </a>
        </li>
    </ul>

    <!-- After routes -->
    <ng-container *ngTemplateOutlet="desktopAfterTemplate || defaultDesktopAfterTemplate"></ng-container>
    <ng-template #defaultDesktopAfterTemplate></ng-template>
</nav>
